import React, { useState } from 'react';
import '../style/Home.css';


const Home = () => {
  const [activeOverlay, setActiveOverlay] = useState(null);

  const openOverlay = (overlayName) => {
    setActiveOverlay(overlayName);
  };

  const closeOverlay = () => {
    setActiveOverlay(null);
  };

  return (
    <div className="home">
      <header className="header">
        <h1 className="title">Aberrest</h1>
        <div className="logo-animated"></div>
      </header>
      <h2 className="subtitle">Applied AI Research & Development</h2>

      <div className="selection-container">
        <div className="selection one" onClick={() => openOverlay('about')}>
          <span className="selection-text">About</span>
        </div>

        <div className="selection two" onClick={() => openOverlay('projects')}>
          <span className="selection-text">Projects</span>
        </div>

        <div className="selection three" onClick={() => openOverlay('contact')}>
          <span className="selection-text">Blog</span>
        </div>
      </div>

      {activeOverlay && (
        <div className="overlay active" onClick={closeOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <span className="overlay-close" onClick={closeOverlay}>&times;</span>
            {activeOverlay === 'about' && <h2>About Content</h2>}
            {activeOverlay === 'projects' && <h2>Projects Content</h2>}
            {activeOverlay === 'contact' && <h2>Blog Content</h2>}
            {/* Add more content for each overlay as needed */}
          </div>
        </div>
      )}

      <footer className="footer">
        <a href="/">Terms of Use</a>
        <a href="/">Privacy Policy</a>
        <span> Aberrest © 2024-Present. All rights reserved.</span>
      </footer>
    </div>
  );
};

export default Home;